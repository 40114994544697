var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div-main-list-page invoice-main" },
    [
      _vm.view === _vm.INVOICE_VIEW.MAIN_INVOICE
        ? _c("div", { staticClass: "div-main-list-page-title" }, [
            _c("h1", [_vm._v("Invoice")]),
          ])
        : _vm._e(),
      _vm.view === _vm.INVOICE_VIEW.MAIN_INVOICE
        ? _c(
            "el-row",
            { staticClass: "top-tab-botton-bar", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "tab-bar",
                  staticStyle: { "margin-bottom": "8px" },
                  attrs: { xs: 24, sm: 8, md: 10 },
                },
                [
                  _vm.isSellerView
                    ? _c(
                        "el-tabs",
                        {
                          on: {
                            "tab-click": function ($event) {
                              return _vm.handleGetInvoices(
                                _vm.invoicePagination.pageSize,
                                0
                              )
                            },
                          },
                          model: {
                            value: _vm.viewInvoiceTabActive,
                            callback: function ($$v) {
                              _vm.viewInvoiceTabActive = $$v
                            },
                            expression: "viewInvoiceTabActive",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Pending Credit Authorization",
                              name: _vm.INVOICE_TAB
                                .RECEIVABLE_PENDING_CREDIT_AUTHORIZATION,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Pending Authorized Signatory Approval",
                              name: _vm.INVOICE_TAB
                                .RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Approved",
                              name: _vm.INVOICE_TAB
                                .RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Rejected",
                              name: _vm.INVOICE_TAB.RECEIVABLE_REJECTED,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Sent",
                              name: _vm.INVOICE_TAB.RECEIVABLE_SENT,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Paid",
                              name: _vm.INVOICE_TAB.RECEIVABLE_PAID,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Archive",
                              name: _vm.INVOICE_TAB.RECEIVABLE_CANCELLED,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-tabs",
                        {
                          on: {
                            "tab-click": function ($event) {
                              return _vm.handleGetInvoices(
                                _vm.invoicePagination.pageSize,
                                0
                              )
                            },
                          },
                          model: {
                            value: _vm.viewInvoiceTabActive,
                            callback: function ($$v) {
                              _vm.viewInvoiceTabActive = $$v
                            },
                            expression: "viewInvoiceTabActive",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Pending Trader Approval",
                              name: _vm.INVOICE_TAB
                                .PAYABLE_PENDING_TRADER_APPROVAL,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Pending Authorized Signatory Approval",
                              name: _vm.INVOICE_TAB
                                .PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Approved",
                              name: _vm.INVOICE_TAB
                                .PAYABLE_AUTHORIZED_SIGNATORY_APPROVED,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Paid",
                              name: _vm.INVOICE_TAB.PAYABLE_PAID,
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "Rejected",
                              name: _vm.INVOICE_TAB.PAYABLE_REJECTED,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "flex-end",
                  staticStyle: { "white-space": "nowrap" },
                  attrs: { xs: 24, sm: 16, md: 14 },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "round-input search-bar",
                      attrs: { placeholder: "Search Order No." },
                      nativeOn: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleGetInvoices(
                            _vm.invoicePagination.pageSize,
                            0,
                            true
                          )
                        },
                      },
                      model: {
                        value: _vm.invoiceSearch,
                        callback: function ($$v) {
                          _vm.invoiceSearch = $$v
                        },
                        expression: "invoiceSearch",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleGetInvoices(
                              _vm.invoicePagination.pageSize,
                              0,
                              true
                            )
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  (_vm.viewInvoiceTabActive ===
                    _vm.INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL &&
                    _vm.hasPermission(
                      _vm.$permissionFunctionCode.TRADER_APPROVAL
                    )) ||
                  ((_vm.viewInvoiceTabActive ===
                    _vm.INVOICE_TAB
                      .PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL ||
                    _vm.viewInvoiceTabActive ===
                      _vm.INVOICE_TAB
                        .RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL) &&
                    _vm.hasPermission(
                      _vm.$permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL
                    ))
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "padding-top": "5px",
                            "white-space": "nowrap",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                round: "",
                                outline: "",
                                type: "success",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.authSignatoryTraderApproveReject(
                                    _vm.viewInvoiceTabActive ===
                                      _vm.INVOICE_TAB
                                        .PAYABLE_PENDING_TRADER_APPROVAL,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(" Approve ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { round: "", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.authSignatoryTraderApproveReject(
                                    _vm.viewInvoiceTabActive ===
                                      _vm.INVOICE_TAB
                                        .PAYABLE_PENDING_TRADER_APPROVAL,
                                    false
                                  )
                                },
                              },
                            },
                            [_vm._v(" Reject ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                    ? _c(
                        "span",
                        { staticStyle: { "padding-top": "5px" } },
                        [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "primary-button",
                              attrs: { trigger: "click" },
                              on: { command: _vm.handleCreateInvoiceSelect },
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(" Create Invoice"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "EX_WHARF" } },
                                    [_vm._v("Ex-Wharf")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "DELIVERED" } },
                                    [_vm._v("Delivered")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: { command: "BARGING_NOMINATION" },
                                    },
                                    [_vm._v("Barging Nomination")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.view === _vm.INVOICE_VIEW.MAIN_INVOICE
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.invoiceTableLoading,
                  expression: "invoiceTableLoading",
                },
              ],
              staticClass: "div-main-list-page-table",
            },
            [
              !_vm.invoiceTableLoading
                ? _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.invoiceTableData,
                        height: "100%",
                        border: true,
                        "header-cell-style": _vm.HEADER_CELL_STYLE,
                        "cell-style": _vm.CELL_STYLE,
                      },
                      on: { "selection-change": _vm.handleInvoiceSelectChange },
                    },
                    [
                      (_vm.viewInvoiceTabActive ===
                        _vm.INVOICE_TAB.PAYABLE_PENDING_TRADER_APPROVAL &&
                        _vm.hasPermission(
                          _vm.$permissionFunctionCode.TRADER_APPROVAL
                        )) ||
                      ((_vm.viewInvoiceTabActive ===
                        _vm.INVOICE_TAB
                          .PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL ||
                        _vm.viewInvoiceTabActive ===
                          _vm.INVOICE_TAB
                            .RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL) &&
                        _vm.hasPermission(
                          _vm.$permissionFunctionCode
                            .AUTHORIZED_SIGNATORY_APPROVAL
                        ))
                        ? _c("el-table-column", {
                            key: "checkbox",
                            attrs: { type: "selection", "min-width": "40" },
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: { label: "Type", width: "100px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.capitalizeFirstLetter(
                                          scope.row.invoiceType
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          480271369
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Status", width: "250px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-tag",
                                      style: _vm.getStatusTagStyle(
                                        _vm.isSellerView
                                          ? scope.row.sellerInvoiceStatus
                                          : scope.row.buyerInvoiceStatus
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isSellerView
                                              ? _vm.capitalizeFirstLetter(
                                                  scope.row.sellerInvoiceStatus,
                                                  "_"
                                                )
                                              : _vm.capitalizeFirstLetter(
                                                  scope.row.buyerInvoiceStatus,
                                                  "_"
                                                )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3968396858
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Order No",
                          prop: "orderNo",
                          width: "200px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Company",
                          width: "150px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isSellerView
                                          ? scope.row.buyerCompanyName
                                          : scope.row.sellerCompanyName
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1395261942
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Order Type",
                          width: "150px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.capitalizeFirstLetter(
                                          scope.row.orderType,
                                          "_"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2124330757
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Vessel/ Terminal",
                          width: "150px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.orderVesselImo
                                          ? scope.row.orderVesselName
                                          : scope.row.orderTerminal
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          938447090
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Item",
                          width: "250px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.invoiceItems,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "el-tag",
                                          {
                                            staticStyle: {
                                              "font-size": "11px",
                                            },
                                            attrs: { type: "info" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3303914339
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Quantity", width: "100px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.invoiceItems,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.quantity
                                              ? `${Number(
                                                  item.quantity
                                                ).toFixed(3)} ${
                                                  item.unit || ""
                                                }`
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          714290984
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Supply Date", width: "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.invoiceItems,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.supplyDate
                                              ? _vm
                                                  .$moment(item.supplyDate)
                                                  .format("DD-MM-YYYY")
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3353528511
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Invoice Total",
                          width: "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        `$${_vm.numberWithCommas(
                                          scope.row.invoiceTotal,
                                          2
                                        )}`
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1637399428
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Invoice Due Date", width: "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$moment(scope.row.invoiceDueDate)
                                            .format("DD-MM-YYYY")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3454383577
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Payment Term",
                          prop: "orderPaymentTerm",
                          width: "120px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.capitalizeFirstLetter(
                                          scope.row.orderPaymentTerm,
                                          "_"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1493276105
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Order Created By",
                          prop: "sellerName",
                          width: "150px",
                        },
                      }),
                      _vm.invoiceTableMenuTermList
                        ? _c("el-table-column", {
                            attrs: { width: "50px", fixed: "right" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dropdown-action-menu", {
                                        attrs: {
                                          actionList:
                                            _vm.invoiceTableMenuTermList,
                                          propsData: scope.row,
                                        },
                                        on: {
                                          handleDropdownMenuClick:
                                            _vm.handleInvoiceDropdownMenuClick,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2217992207
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.invoiceTableLoading
                ? _c(
                    "div",
                    { staticClass: "div-main-list-page-table-pagination" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.invoicePagination.pageSize,
                          "page-sizes": [5, 10, 20, 50, 100],
                          "current-page": _vm.invoicePagination.pageNumber,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.invoicePagination.total,
                        },
                        on: {
                          "size-change": _vm.handleInvoicePageSizeChange,
                          "current-change": _vm.handleInvoicePageChange,
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.invoicePagination,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.invoicePagination,
                              "pageSize",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.view !== _vm.INVOICE_VIEW.MAIN_INVOICE
        ? _c("invoice-form", {
            attrs: {
              propsView: _vm.view,
              invoiceId: _vm.invoiceId,
              data: this.postedListData,
              orderType: _vm.orderType,
              isSellerView: _vm.isSellerView,
            },
            on: {
              handleCloseInvoiceForm: _vm.handleCloseInvoiceForm,
              handleInvoiceFormEditButtonClick:
                _vm.handleInvoiceFormEditButtonClick,
            },
          })
        : _vm._e(),
      _c("dialog-invoice-send", {
        attrs: {
          invoiceId: _vm.invoiceSelected ? _vm.invoiceSelected.id : "",
          dialogSendInvoice: _vm.dialogSendInvoice,
        },
        on: { handleSendInvoiceDialogClose: _vm.handleSendInvoiceDialogClose },
      }),
      _c("dialog-invoice-payment", {
        attrs: {
          invoice: _vm.selectedInvoiceForPayment || {},
          dialogInvoicePayment: _vm.dialogInvoicePayment,
          isSellerView: _vm.isSellerView,
        },
        on: {
          handleInvoicePaymentDialogClose: function ($event) {
            _vm.dialogInvoicePayment = false
          },
        },
      }),
      _c("file-preview", {
        attrs: { visible: _vm.isPreviewFile, file: _vm.previewFileData },
        on: {
          closed: function ($event) {
            _vm.dialogInvoicePayment = true
          },
          "update:visible": function ($event) {
            _vm.isPreviewFile = $event
          },
        },
      }),
      _vm.blockchainInfoVisible
        ? _c("dialog-blockchain-info", {
            attrs: { propsData: _vm.blockchainData },
            on: { onClose: _vm.handleCloseBlockchainInfo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }